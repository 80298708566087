import {
  developmentSettings,
  productionSettings,
  stageSettings,
  stagingSettings,
  testserverSettings,
} from '@twisto/environment'
import { getDomainSettings } from '@twisto/environment/domain-settings'

const COMMON = {
  MOCK_API: false,
}
const PRODUCTION = {
  ...COMMON,
  ...productionSettings,
  RUNTIME: 'production' as const,
}
const STAGINGSERVER = {
  ...COMMON,
  ...stagingSettings,
  RUNTIME: 'staging' as const,
}
const STAGESERVER = {
  ...COMMON,
  ...stageSettings,
  RUNTIME: 'stage' as const,
}
const TESTSERVER = {
  ...COMMON,
  ...testserverSettings,
  RUNTIME: 'testserver' as const,
}
const DEVELOP = {
  ...COMMON,
  ...developmentSettings,
  RUNTIME: 'development' as const,
}

type Runtime =
  | 'production'
  | 'testserver'
  | 'integration'
  | 'development'
  | 'staging'
  | 'stage'

export type EnvConfig = {
  region: GLOBALS.Region
  RUNTIME: Runtime
  MOCK_API: boolean
  HOST: string
  BASE_DOMAIN: string
  STATIC_DOMAIN: string
  EXPERIMENT_API_ENDPOINT: `${string}${typeof API_PATHS.experiment}`
  FEDERATED_GRAPHQL_ENDPOINT: `${string}${typeof API_PATHS.federatedGraphql}`
  FILE_UPLOAD_ENDPOINT: `${string}${typeof API_PATHS.fileUpload}`
  PSP_API_ENDPOINT: `${string}${typeof API_PATHS.psp}`
  MERCHANT_PORTAL_API_ENDPOINT: `${string}${typeof API_PATHS.merchantPortal}`
  TWISTOJS_API_ENDPOINT: `${string}${typeof API_PATHS.twistoJS}`
  TWISTOJS_DOMAIN: string
  MARQETA_CARD_WIDGET_JS: string
  OCEAN_CARD_WIDGET_JS: string
  MERCHANT_SMI_API_ENDPOINT: `${string}${typeof API_PATHS.merchantSMIApi}`
  TWISTO_JS_SCRIPT_URL_V2: `${string}${typeof API_PATHS.twistoJsScriptV2}`
  TWISTO_JS_SCRIPT_URL_V1: `${string}${typeof API_PATHS.twistoJsScriptV1}`
  DAKTELA_GUI_SERVER: string
}

const apiSubdomain = 'api.'
const protocol = 'http://'
const secureProtocol = 'https://'

export const API_PATHS = {
  experiment: '/abtest/experiment/',
  // new hive federated API endpoint for customer and flow
  federatedGraphql: '/customer/v6/graphql/',
  // Endpoint for file upload within customer app
  fileUpload: '/customer/v6/file-upload/',
  merchantPortal: '/psp/portal',
  // used for demo-eshop when calling merchant API
  merchantSMIApi: '/psp/smi',
  psp: '/psp/gateway/v1',
  twistoJS: '/v2.5/',
  twistoJsScriptV2: '/v2/lib/twisto.js',
  twistoJsScriptV1: '/v1/lib/twisto.js',
} as const

const getUrls = ({
  apiEndpoint,
  environment,
  host,
  protocolPrefix = secureProtocol,
  staticDomain,
  iframeDomain,
}: {
  apiEndpoint: string
  environment: Runtime
  host: string
  protocolPrefix?: typeof secureProtocol | typeof protocol
  staticDomain: string
  iframeDomain?: string
}) => {
  let experimentEndpoint = `${apiEndpoint}${API_PATHS.experiment}` as const
  let daktelaUrl = 'https://twisto.daktela.com/'

  const twistoJSDomain = iframeDomain ?? `${protocolPrefix}${host}`

  // For development and teservers environment, we use the production API
  if (environment !== 'production') {
    experimentEndpoint = `https://api.twisto.cz${API_PATHS.experiment}`
    daktelaUrl = 'https://test-twisto.daktela.com/'
  }

  return {
    HOST: host,
    BASE_DOMAIN: `${protocolPrefix}${host}`,
    STATIC_DOMAIN: staticDomain,
    EXPERIMENT_API_ENDPOINT: experimentEndpoint,
    FEDERATED_GRAPHQL_ENDPOINT: `${apiEndpoint}${API_PATHS.federatedGraphql}`,
    FILE_UPLOAD_ENDPOINT: `${apiEndpoint}${API_PATHS.fileUpload}`,
    PSP_API_ENDPOINT: `${apiEndpoint}${API_PATHS.psp}`,
    MERCHANT_PORTAL_API_ENDPOINT: `${apiEndpoint}${API_PATHS.merchantPortal}`,
    TWISTOJS_API_ENDPOINT: `${apiEndpoint}${API_PATHS.twistoJS}`,
    TWISTOJS_DOMAIN: twistoJSDomain,
    MERCHANT_SMI_API_ENDPOINT: `${apiEndpoint}${API_PATHS.merchantSMIApi}`,
    TWISTO_JS_SCRIPT_URL_V2: `${apiEndpoint}${API_PATHS.twistoJsScriptV2}`,
    TWISTO_JS_SCRIPT_URL_V1: `${apiEndpoint}${API_PATHS.twistoJsScriptV1}`,
    DAKTELA_GUI_SERVER: daktelaUrl,
  } as const
}

export const getEnvConfig = (hostArg?: string): EnvConfig => {
  // dummy values for server rendering
  if (typeof window === 'undefined' && typeof hostArg === 'undefined') {
    return {
      ...DEVELOP,
      ...getUrls({
        apiEndpoint: '',
        environment: 'development',
        host: '',
        staticDomain: '',
      }),
      region: 'CZ',
    }
  }

  const { environment, baseDomain, host, region } = getDomainSettings(
    hostArg ?? window.location.host
  )

  /** localhost */
  if (environment === 'development' || region === null) {
    const { MOCK_PORT } = developmentSettings
    const apiHostname =
      baseDomain.includes('localhost') || baseDomain.startsWith('192.')
        ? baseDomain.replace('3000', MOCK_PORT.toString())
        : `api.${baseDomain.replace('3000', MOCK_PORT.toString())}`
    const apiEndpoint = `${protocol}${apiHostname}`

    return {
      ...DEVELOP,
      ...getUrls({
        apiEndpoint,
        environment,
        host,
        protocolPrefix: protocol,
        staticDomain: protocol + host,
      }),
      region: region ?? 'CZ',
      MOCK_API: true,
    }
  }

  /** production */
  if (environment === 'production') {
    const apiEndpoint = `${secureProtocol}${apiSubdomain}${baseDomain}`
    const staticDomain = 'https://static.twistopay.com'
    const iframeDomain = `${secureProtocol}www.${baseDomain}`

    return {
      region,
      ...PRODUCTION,
      ...getUrls({
        apiEndpoint,
        environment,
        host,
        staticDomain,
        iframeDomain,
      }),
    }
  }

  /** staging */
  if (environment === 'staging') {
    const apiEndpoint = `${secureProtocol}${apiSubdomain}${baseDomain}`
    const staticDomain = `${secureProtocol}static.${baseDomain.replace(
      /^\w\w\./,
      ''
    )}`
    const iframeDomain = `${secureProtocol}www.${baseDomain}`

    return {
      region,
      ...STAGINGSERVER,
      ...getUrls({
        apiEndpoint,
        environment,
        host,
        staticDomain,
        iframeDomain,
      }),
    }
  }

  /** stage */
  if (environment === 'stage') {
    const apiEndpoint = `${secureProtocol}${apiSubdomain}${baseDomain}`
    const staticDomain = `${secureProtocol}static.${baseDomain.replace(
      /^\w\w\./,
      ''
    )}`
    const iframeDomain = `${secureProtocol}www.${baseDomain}`

    return {
      region,
      ...STAGESERVER,
      ...getUrls({
        apiEndpoint,
        environment,
        host,
        staticDomain,
        iframeDomain,
      }),
    }
  }

  /** testserver */
  if (environment === 'testserver') {
    const apiEndpoint = `${secureProtocol}${apiSubdomain}${baseDomain}`
    const staticDomain = `${secureProtocol}static.${baseDomain.replace(
      /^\w\w\./,
      ''
    )}`
    const iframeDomain = `${secureProtocol}www.${baseDomain}`

    return {
      region,
      ...TESTSERVER,
      ...getUrls({
        apiEndpoint,
        environment,
        host,
        staticDomain,
        iframeDomain,
      }),
    }
  }

  /** integration devspace */
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (environment === 'integration') {
    const apiEndpoint = `${protocol}${apiSubdomain}${baseDomain}`
    const staticDomain = `${protocol}${baseDomain.replace(/^\w\w\./, '')}`
    const iframeDomain = `${protocol}www.${baseDomain}`

    return {
      ...DEVELOP,
      region: region.toUpperCase() as GLOBALS.Region,
      ...getUrls({
        apiEndpoint,
        environment,
        host,
        staticDomain,
        iframeDomain,
      }),
      BASE_DOMAIN: `${protocol}${host}`,
    }
  }

  throw new Error('environment not recognized')
}

export const envConfig = getEnvConfig()
