export const size = {
  0: '0px',
  4: '4px',
  8: '8px',
  12: '12px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  48: '48px',
  56: '56px',
  60: '60px',
  64: '64px',
  72: '72px',
  80: '80px',
  88: '88px',
  96: '96px',
  104: '104px',
  112: '112px',
  120: '120px',
  128: '128px',
} as const
