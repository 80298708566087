import type {
  ActionPaletteColor,
  AdditionalPaletteColor,
  BackgroundPaletteColor,
  BrandPaletteColor,
  NeutralPaletteColor,
  NewThemePalette,
  OtherPaletteColor,
  StatePaletteColor,
} from './themes'

export const neutral = {
  100: '#9c919e1f', // with opacity
  200: '#9c919e3d', // with opacity
  300: '#9c919e5c', // with opacity
  600: '#9C919E',
  700: '#7E6F80',
  800: '#655368',
  900: '#1A0826',
} as const satisfies NeutralPaletteColor

export const brand = {
  100: '#3BBAB21F', // with opacity
  200: '#3BBAB23F', // with opacity
  400: '#A3DCD9',
  500: '#3BBAB2',
} as const satisfies BrandPaletteColor

export const action = {
  '000': '#FFFFFF',
  400: '#A3DCD9',
  500: '#3BBAB2', // this just a fallback. Use gradient instead
  gradient: 'linear-gradient(45deg, #3BBAB2 0%, #3BBAB2 100%)',
} as const satisfies ActionPaletteColor

export const background = {
  primary: '#FFFFFF',
  secondary: '#F5F6F7',
} as const satisfies BackgroundPaletteColor

export const other = {
  info500: '#795CD3',
  white: '#FFFFFF',
  overlay: '#1a08267a', // with opacity
  overlayDarker: '#1a0826b8', // with opacity
  overlayWhite12: '#ffffff1f', // with opacity
  overlayTextBg: '#1a0826b8', // with opacity
  overlayNeutral: '#ffffffb8', // with opacity
} as const satisfies OtherPaletteColor

export const success = {
  100: '#25a73f1f', // with opacity
  200: '#25a73f3d', // with opacity
  500: '#25A73F',
} as const satisfies StatePaletteColor

export const warning = {
  100: '#f082051f', // with opacity
  200: '#f082053d', // with opacity
  500: '#F08205',
} as const satisfies StatePaletteColor

export const error = {
  100: '#ca16161f', // with opacity
  200: '#ca16163d', // with opacity
  500: '#CA1616',
} as const

export const additionalColor = {
  purple: {
    100: '#795cd31f', // with opacity
    200: '#795cd33d', // with opacity
    500: '#795CD3',
    700: '#420A87', // used on homepage
  },
} as const satisfies AdditionalPaletteColor

export const palette = {
  neutral,
  brand,
  action,
  background,
  other,
  success,
  warning,
  error,
  additionalColor,
} as const satisfies NewThemePalette
