import { colors, sizes } from './icons.styles'
import type {
  IconProps as IconPropsType,
  createSvgIconFromGenerated,
} from './utils'
import { createIconA11yProps } from './utils'

const utils = {
  createIconA11yProps,
}

export type IconComponent = ReturnType<typeof createSvgIconFromGenerated>
export type IconProps = IconPropsType
export * from './color'
export * from './inline'
export * from './big'
// category icons
export * from './category'

export * from './constants'
export { utils, sizes, colors }
